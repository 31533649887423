<template>
  <v-btn :loading="loadingExcel" color="primary" depressed small @click="exportExcel" class="ml-2">
    {{ exportText }}
    <v-icon class="ml-2" size="18" color="white">mdi-file-excel</v-icon>
  </v-btn>
</template>

<script>
import { downloadFile } from "@/utils/helpers";

export default {
  props: {
    options: { type: Object, required: true, default: () => {} },
    exportText: { type: String, required: true },
    exportExcelItemsAction: { type: Function, required: true },
  },
  data: () => ({
    loadingExcel: null,
  }),
  methods: {
    async exportExcel() {
      this.loadingExcel = true;
      try {
        const response = await this.exportExcelItemsAction(this.options);
        const random = Date.now().toString().slice(-3);
        const fileName = `${this.$route.name}_${this.$moment().format("DDMMYY")}_${random}.xlsx`;

        downloadFile(response, fileName);
      } catch {
        this.$notifyError(undefined, "Ha habido un error en la descarga del excel.");
      } finally {
        this.loadingExcel = false;
      }
    },
  },
};
</script>

<style scoped></style>
